$white: #ffffff;
$error: #eb5757;
$warning: #ffac14;
$valid: #49cc6e;
$primary: #00afff;
$gray: #f2f2f2;
//theme
$bg: #515154;
$footer: #1d1d1f;
$layout: #f5f5f7;
$dark-color: #b8e9ff;
$black: #444;
$light-color: #666;
$border: 1px solid rgba(0, 0, 0, 0.1);
$border-hover: 0.1rem solid var($black);
$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 8vh;

$line: #d9d9d9;